import {
  Button,
  Card,
  Grid,
  GridItem,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { lookupVendorIdDept } from '../../services/LookupService'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { Vendor } from '../../types/BusinessPartnerType'
import { ItemDetailsType } from '../../types/ItemDetailsType'
import {
  convertToFactoryOptions,
  getValidFactoryOptionsFromItemDetails,
} from '../../utils/FactoryLocationsUtil'
import { Actions } from './Actions'

interface SelectFactoriesProps {
  onNext: (
    factoryEffectiveStartDate: Date,
    factoryEffectiveEndDate: Date,
    newFactoryLocation: { [key: string]: AutoCompleteOptionType },
    newVOP: { [key: string]: AutoCompleteOptionType },
    removedFactoryLocation: { [key: string]: AutoCompleteOptionType },
  ) => void
  onPrev: () => void
  action: Actions
  vendorIds: string[]
  factoryEffectiveStartDate?: Date
  factoryEffectiveEndDate?: Date
  newFactoryLocation: { [key: string]: AutoCompleteOptionType }
  newVOP: { [key: string]: AutoCompleteOptionType }
  removedFactoryLocation: { [key: string]: AutoCompleteOptionType }
  dept: string
  itemsByVendor: { [key: string]: ItemDetailsType[] }
}
const SelectFactories: React.FC<SelectFactoriesProps> = ({
  onNext,
  onPrev,
  action,
  vendorIds,
  factoryEffectiveStartDate,
  factoryEffectiveEndDate,
  newFactoryLocation,
  newVOP,
  removedFactoryLocation,
  dept,
  itemsByVendor,
}) => {
  const [newFactoryEffectiveStartDate, setNewFactoryEffectiveStartDate] =
    useState<Date | undefined>(factoryEffectiveStartDate)
  const [newFactoryEffectiveEndDate, setNewFactoryEffectiveEndDate] = useState<
    Date | undefined
  >(factoryEffectiveEndDate)
  const { businessPartner, getVendorByGmsVendorNumber } =
    useBusinessPartnerInfo()
  const locations = businessPartner?.locations
  const [newFactoryLocationOption, setNewFactoryLocationOption] = useState<{
    [key: string]: AutoCompleteOptionType
  }>(newFactoryLocation)
  const [removedFactoryLocationOption, setRemovedFactoryLocationOption] =
    useState<{ [key: string]: AutoCompleteOptionType }>(removedFactoryLocation)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const vendors = vendorIds.map((vendorId) =>
    getVendorByGmsVendorNumber(vendorId),
  )
  const [newVOPOption, setNewVOPOption] = useState<{
    [key: string]: AutoCompleteOptionType
  }>(newVOP)
  const factoryToRemoveOptions = useRef<Map<string, AutoCompleteOptionType[]>>(
    new Map<string, AutoCompleteOptionType[]>(),
  )

  const vops = useRef<Map<string, AutoCompleteOptionType[]>>(
    new Map<string, AutoCompleteOptionType[]>(),
  )

  useEffect(() => {
    if (action === Actions.DeleteFactory) {
      const isValidNewFactoryInput =
        newFactoryEffectiveStartDate &&
        newFactoryEffectiveEndDate &&
        newFactoryEffectiveEndDate >= newFactoryEffectiveStartDate
          ? true
          : false
      setIsFormValid(isValidNewFactoryInput)
    } else if (action === Actions.AddFactory) {
      const isValidNewFactoryInput =
        !isEmpty(newFactoryLocationOption) &&
        newFactoryEffectiveStartDate &&
        newFactoryEffectiveEndDate &&
        !isEmpty(newVOPOption) &&
        newFactoryEffectiveEndDate >= newFactoryEffectiveStartDate
          ? true
          : false
      setIsFormValid(isValidNewFactoryInput)
    } else {
      const isValidNewFactoryInput =
        !isEmpty(newFactoryLocationOption) &&
        newFactoryEffectiveStartDate &&
        newFactoryEffectiveEndDate &&
        !isEmpty(newVOPOption) &&
        !isEmpty(removedFactoryLocationOption) &&
        newFactoryEffectiveEndDate >= newFactoryEffectiveStartDate
          ? true
          : false
      setIsFormValid(isValidNewFactoryInput)
    }
  }, [
    newFactoryLocationOption,
    newFactoryEffectiveStartDate,
    newFactoryEffectiveEndDate,
    setIsFormValid,
    newVOPOption,
    removedFactoryLocationOption,
    action,
  ])

  useEffect(() => {
    if (
      newFactoryEffectiveStartDate &&
      newFactoryEffectiveEndDate &&
      vendors &&
      vendors.length > 0
    ) {
      vendors.forEach((vendor: Vendor | undefined) => {
        const validOptions = getValidFactoryOptionsFromItemDetails(
          itemsByVendor[vendor!!.gms_vendor_number],
          newFactoryEffectiveStartDate!!,
          newFactoryEffectiveEndDate!!,
          vendor!!.gms_vendor_number,
        )
        if (validOptions && validOptions.length > 0) {
          factoryToRemoveOptions.current.set(
            vendor?.gms_vendor_number!!,
            validOptions,
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFactoryEffectiveStartDate, newFactoryEffectiveEndDate])

  useEffect(() => {
    if (vendors && vendors.length > 0) {
      vendors.forEach((vendor: Vendor | undefined) => {
        const vendorDept =
          vendor?.gms_vendor_number +
          '-' +
          dept.substring(0, dept.indexOf('-')).trim()
        const fetchLookUpVendorId = async () => {
          const result = await lookupVendorIdDept(vendorDept)

          vops.current.set(vendorDept, result)
        }
        fetchLookUpVendorId()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, vendorIds])

  const goToReview = () => {
    newFactoryEffectiveStartDate &&
      newFactoryEffectiveEndDate &&
      newFactoryLocationOption &&
      newVOPOption &&
      onNext(
        newFactoryEffectiveStartDate,
        newFactoryEffectiveEndDate,
        newFactoryLocationOption,
        newVOPOption,
        removedFactoryLocationOption,
      )
  }

  return (
    <Card>
      <div className="hc-pa-normal">
        <Grid.Container direction="column" align="start">
          <Grid.Item>
            <Grid.Container justify="flex-start">
              <Grid.Item>
                <div style={{ width: '200px', marginRight: '10px' }}>
                  <DatePicker
                    id="effectiveStartDate"
                    label="Effective Start Date"
                    disableDates={{ past: true }}
                    value={
                      newFactoryEffectiveStartDate &&
                      newFactoryEffectiveStartDate.getUTCMonth() +
                        1 +
                        '/' +
                        newFactoryEffectiveStartDate.getUTCDate() +
                        '/' +
                        newFactoryEffectiveStartDate.getUTCFullYear()
                    }
                    errorText="Start date should be less than end date"
                    onUpdate={(id: any, value: any) => {
                      value &&
                        setNewFactoryEffectiveStartDate(
                          new Date(value + ' 00:00:00 GMT'),
                        )
                    }}
                    required
                    location="bottom-left"
                  />
                </div>
              </Grid.Item>
              <Grid.Item>
                <div style={{ width: '200px', marginRight: '10px' }}>
                  <DatePicker
                    id="effectiveEndDate"
                    label="Effective End Date"
                    disableDates={{ past: true }}
                    value={
                      newFactoryEffectiveEndDate &&
                      newFactoryEffectiveEndDate.getUTCMonth() +
                        1 +
                        '/' +
                        newFactoryEffectiveEndDate.getUTCDate() +
                        '/' +
                        newFactoryEffectiveEndDate.getUTCFullYear()
                    }
                    errorText="Start date should be less than end date"
                    onUpdate={(id: any, value: any) => {
                      value &&
                        setNewFactoryEffectiveEndDate(
                          new Date(value + ' 00:00:00 GMT'),
                        )
                    }}
                    required
                    location="bottom-right"
                  />
                </div>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          {vendors &&
            vendors.map(
              (vendor) =>
                vendor && (
                  <Grid.Item className="hc-bg-grey06 hc-ma-normal">
                    <Grid.Container justify="space-between">
                      <Grid.Item>
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                          {`${vendor?.gms_vendor_number} - ${vendor?.vendor_name}`}
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <span>
                          {itemsByVendor[vendor.gms_vendor_number].length} items
                          <Tooltip
                            location="top"
                            content="Copy tcins to clipboard"
                          >
                            <Button
                              size="dense"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  itemsByVendor[
                                    vendor.gms_vendor_number
                                  ].join(),
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="hc-mr-dense"
                                icon={faCopy}
                                size="lg"
                              />
                            </Button>
                          </Tooltip>
                        </span>
                      </Grid.Item>
                    </Grid.Container>
                    {action !== Actions.DeleteFactory && (
                      <div style={{ width: '400px', marginRight: '10px' }}>
                        <Autocomplete
                          id="newFactory"
                          label="Factory To Add"
                          options={convertToFactoryOptions(locations ?? [])}
                          required
                          value={
                            newFactoryLocationOption[vendor.gms_vendor_number]
                          }
                          errorText="Please select a factory"
                          onUpdate={(id, value) => {
                            setNewFactoryLocationOption({
                              ...newFactoryLocationOption,
                              [vendor.gms_vendor_number]: value,
                            })
                          }}
                        />
                        <Autocomplete
                          label="VOP"
                          id="vop"
                          required
                          filter={Autocomplete.filters.none}
                          onUpdate={(id, value) => {
                            setNewVOPOption({
                              ...newVOPOption,
                              [vendor.gms_vendor_number]: value,
                            })
                          }}
                          value={newVOPOption[vendor.gms_vendor_number]}
                          options={
                            vops.current.get(
                              vendor?.gms_vendor_number +
                                '-' +
                                dept.substring(0, dept.indexOf('-')).trim(),
                            ) ?? []
                          }
                        />
                      </div>
                    )}
                    {(action === Actions.ReplaceFactory ||
                      action === Actions.DeleteFactory) && (
                      <div style={{ width: '400px', marginRight: '10px' }}>
                        <Autocomplete
                          id="removeFactory"
                          label="Factory To Remove"
                          options={
                            factoryToRemoveOptions.current.get(
                              vendor.gms_vendor_number,
                            ) || []
                          }
                          required
                          value={
                            removedFactoryLocationOption[
                              vendor.gms_vendor_number
                            ]
                          }
                          disabled={
                            !newFactoryEffectiveStartDate ||
                            !newFactoryEffectiveEndDate ||
                            !factoryToRemoveOptions.current.has(
                              vendor.gms_vendor_number,
                            )
                          }
                          errorText="Please select a factory"
                          // error={!changeRequest.factory_id && showErrorMessage}
                          onUpdate={(id, value) => {
                            setRemovedFactoryLocationOption({
                              ...removedFactoryLocationOption,
                              [vendor.gms_vendor_number]: value,
                            })
                          }}
                        />
                      </div>
                    )}
                  </Grid.Item>
                ),
            )}
          <Grid.Item>
            <Grid.Container justify="flex-end">
              <GridItem>
                <Button
                  type="primary"
                  disabled={!isFormValid}
                  onClick={goToReview}
                  size="dense"
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    Next: Review
                  </span>
                </Button>
              </GridItem>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Card>
  )
}

export default SelectFactories
