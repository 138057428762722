import { Card, Chip } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import React from 'react'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import { LeanFactory } from '../../types/ItemDetailsType'
import LabelValue from '../Common/LabelValue'
import { ReviewStatus } from '../../utils/Constants'
import './ChangeHistory.scss'

interface ChangeHistoryCardProps {
  changeRequest: ChangeRequestType
}

const reduceFactoryDates = (allFactories: any, factory: LeanFactory) => {
  if (allFactories[factory.factory_id]) {
    allFactories[factory.factory_id].dates.push({
      effective_start_date: factory.effective_start_date,
      effective_end_date: factory.effective_end_date,
    })
    allFactories[factory.factory_id].dates = allFactories[
      factory.factory_id
    ].dates.sort((date1: any, date2: any) => {
      return date1.effective_start_date > date2.effective_start_date ? 1 : -1
    })
  } else {
    allFactories[factory.factory_id] = factory
    const factoryDate = {
      effective_start_date: factory.effective_start_date,
      effective_end_date: factory.effective_end_date,
    }
    allFactories[factory.factory_id].dates = [factoryDate]
  }
  return allFactories
}

const ChangeHistoryCard: React.FC<ChangeHistoryCardProps> = ({
  changeRequest,
}) => {
  let allFactoryChanges = []
  const factoriesAdded = changeRequest.diff_factory_timelines
    .flatMap((timeline) => timeline.factories)
    .filter((factory) => factory.is_added)
    .reduce(reduceFactoryDates, {})

  const factoriesRemoved = changeRequest.diff_factory_timelines
    .flatMap((timeline) => timeline.factories)
    .filter((factory) => factory.is_removed)
    .reduce(reduceFactoryDates, {})

  if (changeRequest.has_vop_changed) {
    const vopChanged = changeRequest.vop_timelines

      .flatMap((timeline) => timeline.factories)
      .filter((factory) => factory.is_vop_changed)
      .reduce(reduceFactoryDates, {})

    allFactoryChanges = [
      ...Object.values(factoriesAdded),
      ...Object.values(factoriesRemoved),
      ...Object.values(vopChanged),
    ]
  } else {
    allFactoryChanges = [
      ...Object.values(factoriesAdded),
      ...Object.values(factoriesRemoved),
    ]
  }

  return (
    <>
      {allFactoryChanges.map((factory: any) => {
        return (
          <Card key={changeRequest.change_request_id + factory.factory_id}>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <LabelValue
                  label="Factory: "
                  value={factory.factory_name}
                  dense
                  key={'factoryLabel'}
                />
                <LabelValue
                  label="VOP: "
                  value={factory.vendor_order_spec_id}
                  dense
                  key={'vopLabel'}
                />
                <LabelValue
                  label="Requested by: "
                  value={changeRequest.change_request_initiator.user_name}
                  dense
                  key={'requestedByLabel'}
                />
                <LabelValue
                  label="Vendor Contacts: "
                  value={changeRequest.external_contacts?.[0]}
                  dense
                  key={'vendorContactLabel'}
                />
                {changeRequest.review_status === 'APPROVED' &&
                  !changeRequest.has_vop_changed && (
                    <LabelValue
                      label="Approved by: "
                      value={changeRequest.approved_by}
                      dense
                      key={'approvedLabel'}
                    />
                  )}
                {changeRequest.review_status === 'REJECTED' && (
                  <LabelValue
                    label="Rejected by: "
                    value={changeRequest.rejected_by}
                    dense
                    key={'rejectedByLabel'}
                  />
                )}
              </div>
              <div>
                {factory.is_added &&
                  changeRequest.review_status === ReviewStatus.APPROVED && (
                    <Chip color="success" size="dense">
                      Added
                    </Chip>
                  )}
                {factory.is_removed &&
                  changeRequest.review_status === ReviewStatus.APPROVED && (
                    <Chip className="removed-factory-chip" size="dense">
                      Removed
                    </Chip>
                  )}
                {factory.is_vop_changed && (
                  <Chip color="success" size="dense">
                    VOP Changed
                  </Chip>
                )}
                {factory.is_added &&
                  changeRequest.review_status === ReviewStatus.REJECTED && (
                    <Chip size="dense">Factory Add Request Rejected</Chip>
                  )}
                {factory.is_removed &&
                  changeRequest.review_status === ReviewStatus.REJECTED && (
                    <Chip size="dense">Factory Remove Request Rejected</Chip>
                  )}
                {factory.is_added &&
                  changeRequest.review_status === ReviewStatus.CANCELLED && (
                    <Chip size="dense">Factory Add Request Cancelled</Chip>
                  )}
                {factory.is_removed &&
                  changeRequest.review_status === ReviewStatus.CANCELLED && (
                    <Chip size="dense">Factory Remove Request Cancelled</Chip>
                  )}
              </div>
            </div>
            <div style={{ display: 'flex', fontSize: '12px' }}>
              <p>
                <strong>Requested on: </strong>
              </p>
              <p style={{ marginLeft: '4px' }}>
                <DateFormatter date={changeRequest.created_on} timezone="UTC" />
              </p>
            </div>
            {changeRequest.review_status === 'APPROVED' &&
              !changeRequest.has_vop_changed && (
                <div style={{ display: 'flex', fontSize: '12px' }}>
                  <p>
                    <strong>Approved on: </strong>
                  </p>
                  <p style={{ marginLeft: '4px' }}>
                    <DateFormatter
                      date={changeRequest.approved_on!!}
                      timezone="UTC"
                    />
                  </p>
                </div>
              )}
            {changeRequest.review_status === 'REJECTED' && (
              <div style={{ display: 'flex', fontSize: '12px' }}>
                <p>
                  <strong>Rejected on: </strong>
                </p>
                <p style={{ marginLeft: '4px' }}>
                  <DateFormatter
                    date={changeRequest.rejected_on!!}
                    timezone="UTC"
                  />
                </p>
              </div>
            )}
            {!changeRequest.has_vop_changed && (
              <div style={{ display: 'flex', fontSize: '12px' }}>
                <p>
                  <strong>Factory dates: </strong>
                </p>
                <p style={{ marginLeft: '4px' }}>
                  {factory.dates.map((date: any) => (
                    <React.Fragment key={date}>
                      <DateFormatter
                        date={date.effective_start_date}
                        timezone="UTC"
                      />{' '}
                      - &nbsp;
                      <DateFormatter
                        date={date.effective_end_date}
                        timezone="UTC"
                      />
                      ;&nbsp;
                    </React.Fragment>
                  ))}
                </p>
              </div>
            )}
            {!changeRequest.has_vop_changed && (
              <LabelValue
                label="Reason: "
                value={
                  changeRequest.reason_for_change +
                  '-' +
                  changeRequest.reason_for_change_description
                }
                dense
              />
            )}
            {!changeRequest.has_vop_changed && (
              <LabelValue
                label="Cycle: "
                value={changeRequest.cycle_description?.join()}
                dense
              />
            )}

            {changeRequest.review_status === 'REJECTED' && (
              <LabelValue
                label="Reason for rejection: "
                value={
                  changeRequest.comments_for_rejection
                    ? changeRequest.reason_for_rejection +
                      '-' +
                      changeRequest.comments_for_rejection
                    : changeRequest.reason_for_rejection
                }
                dense
              />
            )}
          </Card>
        )
      })}
    </>
  )
}

export default ChangeHistoryCard
