import React, { useState, useEffect } from 'react'
import { ItemDetailsType, LeanFactory } from '../../types/ItemDetailsType'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { Location } from '../../types/BusinessPartnerType'
import { convertToFactoryOptions } from '../../utils/FactoryLocationsUtil'
import { lookupVendorIdDept } from '../../services/LookupService'

interface ReplaceFactoryFormProps {
  factoryToRemove: LeanFactory
  onReplaceFactory: (
    factoryToRemove: LeanFactory,
    factoryToAdd: LeanFactory,
  ) => void
  onCancel: () => void
  locations: Location[]
  showForm: boolean
  selectedVendor: AutoCompleteOptionType
  itemDetails: ItemDetailsType
}

export const ReplaceFactoryForm: React.FC<ReplaceFactoryFormProps> = ({
  factoryToRemove,
  onReplaceFactory,
  onCancel,
  locations,
  showForm,
  selectedVendor,
  itemDetails,
}) => {
  const [newFactoryLocationOption, setNewFactoryLocationOption] =
    useState<AutoCompleteOptionType>()

  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [newVOP, setNewVOP] = useState<AutoCompleteOptionType>()

  useEffect(() => {
    const isValidNewFactoryInput = newFactoryLocationOption ? true : false
    setIsFormValid(isValidNewFactoryInput)
  }, [newFactoryLocationOption, newVOP, setIsFormValid])

  const replaceFactory = () => {
    if (newFactoryLocationOption && newVOP) {
      onReplaceFactory(factoryToRemove, {
        factory_id: newFactoryLocationOption ? newFactoryLocationOption.id : '',
        factory_name: newFactoryLocationOption
          ? newFactoryLocationOption.label
          : '',
        vendor_order_spec_id: newVOP.id,
        effective_start_date: factoryToRemove.effective_start_date,
        effective_end_date: factoryToRemove.effective_end_date,
        factory_active: true,
      })
    }
  }

  return (
    <>
      {showForm && (
        <Grid.Container>
          <Grid.Item xs={7}>
            <Autocomplete
              id="factory"
              label="Select Replacement Factory"
              options={convertToFactoryOptions(locations)}
              required
              value={newFactoryLocationOption}
              errorText="Please select a factory"
              onUpdate={(id, value) => {
                setNewFactoryLocationOption(value)
              }}
            />
            <Autocomplete
              label="VOP"
              id="vop"
              required
              filter={Autocomplete.filters.none}
              onUpdate={(id, value) => {
                setNewVOP(value)
              }}
              value={newVOP}
              options={async (value: any) => {
                return await lookupVendorIdDept(
                  selectedVendor.id + '-' + itemDetails.department_id,
                )
              }}
            />
          </Grid.Item>
          <Grid.Item className="hc-pt-expanded hc-mt-normal">
            <Button
              type="primary"
              size="dense"
              disabled={!isFormValid}
              onClick={replaceFactory}
            >
              Replace
            </Button>
          </Grid.Item>
          <Grid.Item className="hc-pt-expanded hc-mt-normal">
            <Button size="dense" onClick={onCancel}>
              Cancel
            </Button>
          </Grid.Item>
        </Grid.Container>
      )}
    </>
  )
}
