import {
  FactoryTimelineItem,
  FactoryTimeline,
  LeanFactory,
} from '../types/ItemDetailsType'
import { getDefaultEndDate, getTodaysDate } from './Constants'

export const createEditableFactoryTimeline = (
  factoryTimeLine: FactoryTimeline,
) => {
  const factoryTimelineClone: FactoryTimeline =
    deepCopyFactoryTimeline(factoryTimeLine)
  factoryTimelineClone.factoryTimelineItems = sortFactoryTimelineItems(
    factoryTimelineClone.factoryTimelineItems,
  )
  const factoryTimelineItems: FactoryTimelineItem[] = []
  const todaysDate = getTodaysDate()
  factoryTimelineClone.factoryTimelineItems.forEach((factoryTimeLineItem) => {
    if (
      factoryTimeLineItem.effective_start_date <= todaysDate &&
      todaysDate <= factoryTimeLineItem.effective_end_date
    ) {
      factoryTimeLineItem.effective_start_date = todaysDate
      factoryTimeLineItem.factories.forEach((factory) => {
        factory.effective_start_date = todaysDate
      })
      factoryTimelineItems.push(factoryTimeLineItem)
    } else if (factoryTimeLineItem.effective_end_date > todaysDate) {
      factoryTimelineItems.push(factoryTimeLineItem)
    }
  })
  return { factoryTimelineItems: factoryTimelineItems }
}

export const buildChangeRequestTimeline = (
  modifiedFactoryTimeLine: FactoryTimeline,
  factoryTimeline: FactoryTimeline,
) => {
  const pastFactoryTimelineItems: FactoryTimelineItem[] = []
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 5)
  factoryTimeline.factoryTimelineItems.forEach((factoryTimeLineItem) => {
    if (
      factoryTimeLineItem.effective_start_date <= yesterday &&
      yesterday <= factoryTimeLineItem.effective_end_date
    ) {
      factoryTimeLineItem.effective_end_date = yesterday
      factoryTimeLineItem.factories.forEach((factory) => {
        factory.effective_end_date = yesterday
      })
      pastFactoryTimelineItems.push(factoryTimeLineItem)
    } else if (factoryTimeLineItem.effective_end_date <= yesterday) {
      pastFactoryTimelineItems.push(factoryTimeLineItem)
    }
  })
  const changedFactoryTimeline = {
    factoryTimelineItems: [
      ...modifiedFactoryTimeLine.factoryTimelineItems,
      ...pastFactoryTimelineItems,
    ],
  }

  changedFactoryTimeline.factoryTimelineItems = mergeTimelineItems(
    changedFactoryTimeline.factoryTimelineItems,
  )
  return changedFactoryTimeline
}

export const deepCopyFactoryTimeline = (
  factoryTimeline: FactoryTimeline,
): FactoryTimeline => {
  if (factoryTimeline.factoryTimelineItems.length === 0) {
    return { factoryTimelineItems: [] }
  }
  const factoryTimelineItemsClone = factoryTimeline.factoryTimelineItems.map(
    (factoryTimelineItem) => {
      const factoriesClone = factoryTimelineItem.factories.map((factory) => {
        return { ...factory }
      })
      return {
        ...factoryTimelineItem,
        factories: factoriesClone,
      }
    },
  )

  return { factoryTimelineItems: factoryTimelineItemsClone }
}

export const isFactoryTimelineUpdated = (
  modifiedFactoryTimeLine: FactoryTimeline,
) => {
  for (
    let i = 0;
    i < modifiedFactoryTimeLine.factoryTimelineItems.length;
    i++
  ) {
    for (
      let j = 0;
      j < modifiedFactoryTimeLine.factoryTimelineItems[i].factories.length;
      j++
    ) {
      if (
        modifiedFactoryTimeLine.factoryTimelineItems[i].factories[j].is_added ||
        modifiedFactoryTimeLine.factoryTimelineItems[i].factories[j].is_removed
      ) {
        return true
      }
    }
  }
  return false
}

export const hasSingleFactoryInTimelineItem = (
  factory: LeanFactory,
  factoryTimeline: FactoryTimeline,
) => {
  const factoryTimelineItem = factoryTimeline.factoryTimelineItems.filter(
    (timelineItem) => {
      return (
        timelineItem.effective_start_date.getTime() ===
        factory.effective_start_date.getTime()
      )
    },
  )
  const activeFactories = factoryTimelineItem[0].factories.filter((factory) => {
    return factory.is_added || !factory.is_removed
  })

  return activeFactories.length === 1
}

export const previewNewTimeline = (_factoryTimeline: FactoryTimeline) => {
  const factoryTimeline: FactoryTimeline =
    deepCopyFactoryTimeline(_factoryTimeline)
  factoryTimeline.factoryTimelineItems.forEach((timelineItem) => {
    const activeFactories = timelineItem.factories
      .filter((factory) => {
        return !factory.is_removed
      })
      .map((factory) => {
        factory.is_added = false
        return factory
      })
    timelineItem.factories = activeFactories
  })
  factoryTimeline.factoryTimelineItems = mergeTimelineItems(
    factoryTimeline.factoryTimelineItems,
  )
  return factoryTimeline
}

export const removeFactoryFromFactoryTimeline = (
  factoryToRemove: LeanFactory,
  editableFactoryTimeLine: FactoryTimeline,
) => {
  let factoryTimelineItems: FactoryTimelineItem[] = []
  editableFactoryTimeLine.factoryTimelineItems.forEach(
    (factoryTimelineItem) => {
      const exists = factoryTimelineItem.factories.filter((factory) => {
        return factory.factory_id === factoryToRemove.factory_id
      })
      if (exists) {
        const updatedFactories = factoryTimelineItem.factories
          .map((factory) => {
            return {
              ...factory,
              is_removed: factory.factory_id === factoryToRemove.factory_id,
            }
          })
          .sort((factory1, factory2) => {
            return factory1.factory_id < factory2.factory_id ? -1 : 1
          })
        if (hasNoCommonTimeline(factoryToRemove, factoryTimelineItem)) {
          factoryTimelineItems.push(factoryTimelineItem)
        } else if (hasTimelineInBetween(factoryToRemove, factoryTimelineItem)) {
          factoryTimelineItems.push({
            effective_start_date: factoryToRemove.effective_start_date,
            effective_end_date: factoryToRemove.effective_end_date,
            factories: updatedFactories,
          })
          if (
            factoryToRemove.effective_start_date >
            factoryTimelineItem.effective_start_date
          ) {
            factoryTimelineItems.push({
              effective_start_date: factoryTimelineItem.effective_start_date,
              effective_end_date: new Date(
                factoryToRemove.effective_start_date.getTime() -
                  24 * 60 * 60 * 1000,
              ),
              factories: factoryTimelineItem.factories,
            })
          }
          if (
            factoryToRemove.effective_end_date <
            factoryTimelineItem.effective_end_date
          ) {
            factoryTimelineItems.push({
              effective_start_date: new Date(
                factoryToRemove.effective_end_date.getTime() +
                  24 * 60 * 60 * 1000,
              ),
              effective_end_date: factoryTimelineItem.effective_end_date,
              factories: factoryTimelineItem.factories,
            })
          }
        } else if (hasStartedBefore(factoryToRemove, factoryTimelineItem)) {
          factoryTimelineItems.push({
            effective_start_date: factoryTimelineItem.effective_start_date,
            effective_end_date: factoryToRemove.effective_end_date,
            factories: updatedFactories,
          })
          if (
            factoryToRemove.effective_end_date <
            factoryTimelineItem.effective_end_date
          ) {
            factoryTimelineItems.push({
              effective_start_date: new Date(
                factoryToRemove.effective_end_date.getTime() +
                  24 * 60 * 60 * 1000,
              ),
              effective_end_date: factoryTimelineItem.effective_end_date,
              factories: factoryTimelineItem.factories,
            })
          }
        } else if (hasEndedAfter(factoryToRemove, factoryTimelineItem)) {
          factoryTimelineItems.push({
            effective_start_date: factoryToRemove.effective_start_date,
            effective_end_date: factoryTimelineItem.effective_end_date,
            factories: updatedFactories,
          })
          if (
            factoryToRemove.effective_start_date >
            factoryTimelineItem.effective_start_date
          ) {
            factoryTimelineItems.push({
              effective_start_date: factoryTimelineItem.effective_start_date,
              effective_end_date: new Date(
                factoryToRemove.effective_start_date.getTime() -
                  24 * 60 * 60 * 1000,
              ),
              factories: factoryTimelineItem.factories,
            })
          }
        } else if (
          hasStartedBeforeAndEndedAfter(factoryToRemove, factoryTimelineItem)
        ) {
          factoryTimelineItems.push({
            effective_start_date: factoryTimelineItem.effective_start_date,
            effective_end_date: factoryTimelineItem.effective_end_date,
            factories: updatedFactories,
          })
        }
      } else {
        factoryTimelineItems.push(factoryTimelineItem)
      }
    },
  )

  // adjust dates of factories within timeline item
  factoryTimelineItems = factoryTimelineItems.map((factoryTimelineItem) => {
    const updatedFactories = factoryTimelineItem.factories
      .map((factory) => {
        return {
          ...factory,
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
        }
      })
      .sort((factory1, factory2) => {
        return factory1.factory_id < factory2.factory_id ? -1 : 1
      })
    return {
      ...factoryTimelineItem,
      factories: updatedFactories,
    }
  })
  factoryTimelineItems = mergeTimelineItems(factoryTimelineItems)

  return factoryTimelineItems.sort((t1, t2) => {
    return t1.effective_start_date > t2.effective_start_date ? -1 : 1
  })
}

export const sortFactoryTimelineItems = (
  factoryTimelineItems: FactoryTimelineItem[],
) => {
  const sortedFactoryTimelineItems = factoryTimelineItems
    .sort((t1, t2) => {
      return t1.effective_start_date > t2.effective_start_date ? -1 : 1
    })
    .filter((fact) => {
      return fact.factories[0] && fact.factories[0].factory_id !== 'NFA'
    })

  return mergeTimelineItems(sortedFactoryTimelineItems)
}

export const createEditableVOPFactoryTimeline = (
  factoryTimeLine: FactoryTimeline,
) => {
  const factoryTimelineClone: FactoryTimeline =
    deepCopyFactoryTimeline(factoryTimeLine)
  factoryTimelineClone.factoryTimelineItems = sortFactoryTimelineItems(
    factoryTimelineClone.factoryTimelineItems,
  )
  const factoryTimelineItems: FactoryTimelineItem[] = []
  const todaysDate = getTodaysDate()
  factoryTimelineClone.factoryTimelineItems.forEach((factoryTimeLineItem) => {
    if (factoryTimeLineItem.effective_end_date > todaysDate) {
      factoryTimelineItems.push(factoryTimeLineItem)
    }
  })
  return { factoryTimelineItems: factoryTimelineItems }
}

const mergeTimelineItems = (factoryTimelineItems: FactoryTimelineItem[]) => {
  if (factoryTimelineItems.length === 0) {
    return []
  }
  const newFactoryTimelineItems: FactoryTimelineItem[] = []
  for (let i = 0; i < factoryTimelineItems.length - 1; i++) {
    const currentFactory = factoryTimelineItems[i]
    const nextFactory = factoryTimelineItems[i + 1]
    if (
      areFactoriesEqual(currentFactory.factories, nextFactory.factories) &&
      doFactoriesTimeOverlap(currentFactory, nextFactory)
    ) {
      if (currentFactory.effective_end_date > nextFactory.effective_end_date) {
        nextFactory.effective_end_date = currentFactory.effective_end_date

        nextFactory.factories.forEach((factory) => {
          factory.effective_end_date = currentFactory.effective_end_date
        })
      }
    } else {
      newFactoryTimelineItems.push(currentFactory)
    }
  }

  newFactoryTimelineItems.push(
    factoryTimelineItems[factoryTimelineItems.length - 1],
  )
  return newFactoryTimelineItems
}

const doFactoriesTimeOverlap = (
  factory1: FactoryTimelineItem,
  factory2: FactoryTimelineItem,
) => {
  const factory2EndDateTomorrow = new Date(
    factory2.effective_end_date.getFullYear(),
    factory2.effective_end_date.getMonth(),
    factory2.effective_end_date.getDate() + 1,
  )
  if (
    factory2EndDateTomorrow.getFullYear() ===
      factory1.effective_start_date.getFullYear() &&
    factory2EndDateTomorrow.getMonth() ===
      factory1.effective_start_date.getMonth() &&
    factory2EndDateTomorrow.getDate() ===
      factory1.effective_start_date.getDate()
  ) {
    return true
  }

  // factoryTimeline1 start date is always greater than or equal to factoryTimeline2 start date
  return (
    factory1.effective_start_date < factory2.effective_end_date ||
    Number(factory1.effective_start_date) ===
      Number(factory2.effective_end_date)
  )
}

const areFactoriesEqual = (
  factories1: LeanFactory[],
  factories2: LeanFactory[],
) => {
  let isEqual = true
  if (factories1.length === factories2.length) {
    for (let i = 0; i < factories1.length; i++) {
      if (
        factories1[i].factory_id !== factories2[i].factory_id ||
        factories1[i].is_added ||
        factories2[i].is_added ||
        factories1[i].is_removed ||
        factories2[i].is_removed ||
        factories1[i].vendor_order_spec_id !==
          factories2[i].vendor_order_spec_id
      ) {
        isEqual = false
      }
    }
  } else {
    isEqual = false
  }
  return isEqual
}

export const createNewFactoryTimeline = (): FactoryTimeline => {
  return {
    factoryTimelineItems: [
      {
        effective_start_date: getTodaysDate(),
        effective_end_date: getDefaultEndDate(),
        factories: [],
      },
    ],
  }
}

export const chgVOPToFactoryTimeline = (chgFactory: LeanFactory) => {
  return {
    factoryTimelineItems: [
      {
        effective_start_date: chgFactory.effective_start_date,
        effective_end_date: chgFactory.effective_end_date,
        factories: [chgFactory],
      },
    ],
  }
}

export const addFactoryToFactoryTimeline = (
  factoryToAdd: LeanFactory,
  editableFactoryTimeLine: FactoryTimeline,
) => {
  let factoryTimelineItems: FactoryTimelineItem[] = []
  editableFactoryTimeLine.factoryTimelineItems.forEach(
    (factoryTimelineItem) => {
      const factoryAlreadyExists = factoryTimelineItem.factories.filter(
        (factory) => {
          return factory.factory_id === factoryToAdd.factory_id
        },
      )
      const vopAlreadyExists = factoryTimelineItem.factories.filter(
        (factory) => {
          return (
            factory.vendor_order_spec_id + factory.factory_id ===
            factoryToAdd.vendor_order_spec_id + factoryToAdd.factory_id
          )
        },
      )

      if (factoryAlreadyExists.length > 0 && vopAlreadyExists.length > 0) {
        factoryTimelineItems.push(factoryTimelineItem)
      } else if (hasNoCommonTimeline(factoryToAdd, factoryTimelineItem)) {
        factoryTimelineItems.push(factoryTimelineItem)
      } else if (hasTimelineInBetween(factoryToAdd, factoryTimelineItem)) {
        factoryTimelineItems.push({
          effective_start_date: factoryToAdd.effective_start_date,
          effective_end_date: factoryToAdd.effective_end_date,
          factories: [...factoryTimelineItem.factories, factoryToAdd],
        })
        if (
          factoryToAdd.effective_start_date >
          factoryTimelineItem.effective_start_date
        ) {
          factoryTimelineItems.push({
            effective_start_date: factoryTimelineItem.effective_start_date,
            effective_end_date: new Date(
              factoryToAdd.effective_start_date.getTime() - 24 * 60 * 60 * 1000,
            ),
            factories: factoryTimelineItem.factories,
          })
        }
        if (
          factoryToAdd.effective_end_date <
          factoryTimelineItem.effective_end_date
        ) {
          factoryTimelineItems.push({
            effective_start_date: new Date(
              factoryToAdd.effective_end_date.getTime() + 24 * 60 * 60 * 1000,
            ),
            effective_end_date: factoryTimelineItem.effective_end_date,
            factories: factoryTimelineItem.factories,
          })
        }
      } else if (hasStartedBefore(factoryToAdd, factoryTimelineItem)) {
        factoryTimelineItems.push({
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryToAdd.effective_end_date,
          factories: [...factoryTimelineItem.factories, factoryToAdd],
        })
        if (
          factoryToAdd.effective_end_date <
          factoryTimelineItem.effective_end_date
        ) {
          factoryTimelineItems.push({
            effective_start_date: new Date(
              factoryToAdd.effective_end_date.getTime() + 24 * 60 * 60 * 1000,
            ),
            effective_end_date: factoryTimelineItem.effective_end_date,
            factories: factoryTimelineItem.factories,
          })
        }
      } else if (hasEndedAfter(factoryToAdd, factoryTimelineItem)) {
        factoryTimelineItems.push({
          effective_start_date: factoryToAdd.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
          factories: [...factoryTimelineItem.factories, factoryToAdd],
        })
        if (
          factoryToAdd.effective_start_date >
          factoryTimelineItem.effective_start_date
        ) {
          factoryTimelineItems.push({
            effective_start_date: factoryTimelineItem.effective_start_date,
            effective_end_date: new Date(
              factoryToAdd.effective_start_date.getTime() - 24 * 60 * 60 * 1000,
            ),
            factories: factoryTimelineItem.factories,
          })
        }
      } else if (
        hasStartedBeforeAndEndedAfter(factoryToAdd, factoryTimelineItem)
      ) {
        factoryTimelineItems.push({
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
          factories: [...factoryTimelineItem.factories, factoryToAdd],
        })
      }
    },
  )
  // adjust dates of factories within timeline item
  factoryTimelineItems = factoryTimelineItems.map((factoryTimelineItem) => {
    const afterDupFactoryRemoved: LeanFactory[] = []
    const updatedFactories = factoryTimelineItem.factories
      .map((factory) => {
        return {
          ...factory,
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
        }
      })
      .sort((factory1, factory2) => {
        return factory1.factory_id < factory2.factory_id ? -1 : 1
      })

    updatedFactories.map((factory) => {
      if (
        factoryTimelineItem.effective_start_date ===
          factoryToAdd.effective_start_date &&
        factoryTimelineItem.effective_end_date ===
          factoryToAdd.effective_end_date
      ) {
        if (
          factory.factory_id !== factoryToAdd.factory_id ||
          (factory.factory_id === factoryToAdd.factory_id &&
            factory.vendor_order_spec_id === factoryToAdd.vendor_order_spec_id)
        ) {
          afterDupFactoryRemoved.push({
            ...factory,
            effective_start_date: factoryTimelineItem.effective_start_date,
            effective_end_date: factoryTimelineItem.effective_end_date,
          })
        }
      } else {
        afterDupFactoryRemoved.push({
          ...factory,
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
        })
      }
      // eslint-disable-next-line array-callback-return
      return
    })
    return {
      ...factoryTimelineItem,
      factories: afterDupFactoryRemoved,
    }
  })

  return factoryTimelineItems.sort((t1, t2) => {
    return t1.effective_start_date > t2.effective_start_date ? -1 : 1
  })
}

export const hasGapsInTimeline = (editableFactoryTimeLine: FactoryTimeline) => {
  let hasGaps = false
  editableFactoryTimeLine.factoryTimelineItems.forEach(
    (factoryTimelineItem) => {
      hasGaps =
        factoryTimelineItem.factories.filter((factory) => !factory.is_removed)
          .length === 0 || hasGaps
    },
  )
  return hasGaps
}

export const hasTimelineChanged = (
  editableFactoryTimeLine: FactoryTimeline,
) => {
  let hasChanged = false
  editableFactoryTimeLine.factoryTimelineItems.forEach(
    (factoryTimelineItem) => {
      hasChanged =
        factoryTimelineItem.factories.filter(
          (factory) => factory.is_removed || factory.is_added,
        ).length > 0 || hasChanged
    },
  )
  return hasChanged
}

export const validateFactoryRemoval = (
  editableFactoryTimeLine: FactoryTimeline,
  factoryId: string,
  startDate: Date,
  endDate: Date,
): boolean => {
  let isValid = false
  editableFactoryTimeLine.factoryTimelineItems.forEach(
    (factoryTimelineItem) => {
      if (
        !isValid &&
        factoryTimelineItem.effective_start_date <= startDate &&
        factoryTimelineItem.effective_end_date >= endDate
      ) {
        if (
          factoryTimelineItem.factories
            .map((factory: LeanFactory) => factory.factory_id)
            .includes(factoryId)
        ) {
          //Factory present in the range to remove
          isValid = true
        }
      }
    },
  )
  return isValid
}

const hasNoCommonTimeline = (
  factoryToAdd: LeanFactory,
  factoryTimelineItem: FactoryTimelineItem,
) => {
  return (
    factoryToAdd.effective_end_date <
      factoryTimelineItem.effective_start_date ||
    factoryToAdd.effective_start_date > factoryTimelineItem.effective_end_date
  )
}

const hasTimelineInBetween = (
  factoryToAdd: LeanFactory,
  factoryTimelineItem: FactoryTimelineItem,
) => {
  return (
    factoryToAdd.effective_end_date <= factoryTimelineItem.effective_end_date &&
    factoryToAdd.effective_start_date >=
      factoryTimelineItem.effective_start_date
  )
}

const hasStartedBefore = (
  factoryToAdd: LeanFactory,
  factoryTimelineItem: FactoryTimelineItem,
) => {
  return (
    factoryToAdd.effective_start_date <
      factoryTimelineItem.effective_start_date &&
    factoryToAdd.effective_end_date <= factoryTimelineItem.effective_end_date &&
    factoryToAdd.effective_end_date >= factoryTimelineItem.effective_start_date
  )
}

const hasEndedAfter = (
  factoryToAdd: LeanFactory,
  factoryTimelineItem: FactoryTimelineItem,
) => {
  return (
    factoryToAdd.effective_end_date > factoryTimelineItem.effective_end_date &&
    factoryToAdd.effective_start_date >=
      factoryTimelineItem.effective_start_date &&
    factoryToAdd.effective_start_date <= factoryTimelineItem.effective_end_date
  )
}

const hasStartedBeforeAndEndedAfter = (
  factoryToAdd: LeanFactory,
  factoryTimelineItem: FactoryTimelineItem,
) => {
  return (
    factoryToAdd.effective_start_date <
      factoryTimelineItem.effective_start_date &&
    factoryToAdd.effective_end_date > factoryTimelineItem.effective_end_date
  )
}
