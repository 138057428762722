import React from 'react'
import { LeanFactory } from '../../../types/ItemDetailsType'
import { Location } from '../../../types/BusinessPartnerType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus,
  faCalendarMinus,
} from '@fortawesome/free-regular-svg-icons'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import TimelineDateFlag from './TimelineDateFlag'
import { getCop } from '../../../utils/FactoryLocationsUtil'
import { Chip, Form } from '@enterprise-ui/canvas-ui-react'
import { dateCheck } from '../../../utils/DateCheck'

interface FactoryListProps {
  factories: LeanFactory[]
  selectedFactory?: LeanFactory
  onSelect?: (factory: LeanFactory) => void
  effectiveStartDate: Date
  effectiveEndDate: Date
  locations?: Location[]
  editable: boolean
  changeReason?: string
  changeRequestedBy?: string
  isDateAdjusted?: boolean
  vopChanged: boolean
}

const FactoryList: React.FC<FactoryListProps> = ({
  factories,
  selectedFactory,
  onSelect,
  effectiveStartDate,
  effectiveEndDate,
  locations,
  editable,
  changeReason,
  isDateAdjusted = false,
  vopChanged,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TimelineDateFlag
        effectiveEndDate={effectiveEndDate}
        effectiveStartDate={effectiveStartDate}
        isDateAdjusted={isDateAdjusted}
      />

      {factories.length > 0 &&
        factories.map((factory, index) => (
          <React.Fragment
            key={factory.factory_id + factory.effective_start_date}
          >
            {!factory.is_added &&
              !factory.is_removed &&
              !factory.is_vop_changed && (
                <>
                  <Factory
                    factory={factory}
                    selectedFactory={selectedFactory}
                    onSelect={onSelect}
                    locations={locations}
                    editable={editable}
                    key={factory.factory_id + factory.effective_start_date}
                  ></Factory>
                </>
              )}
            {(factory.is_added || factory.is_removed) && (
              <AddedOrRemovedFactory
                factory={factory}
                selectedFactory={selectedFactory}
                onSelect={onSelect}
                locations={locations}
                editable={editable}
                changeReason={changeReason}
                key={factory.factory_id + factory.effective_start_date}
              ></AddedOrRemovedFactory>
            )}
            {factory.is_vop_changed && (
              <VOPChangeTimeline
                factory={factory}
                selectedFactory={selectedFactory}
                onSelect={onSelect}
                locations={locations}
                editable={editable}
                changeReason={changeReason}
                key={factory.factory_id + factory.effective_start_date}
              ></VOPChangeTimeline>
            )}
          </React.Fragment>
        ))}
      {factories.length === 0 && <p>No Factories Available</p>}
    </div>
  )
}

interface FactoryProps {
  factory: LeanFactory
  selectedFactory?: LeanFactory
  onSelect?: (factory: LeanFactory) => void
  locations?: Location[]
  editable: boolean
}

const Factory: React.FC<FactoryProps> = ({
  factory,
  selectedFactory,
  onSelect,
  locations,
  editable,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '15px',
      }}
    >
      {editable && (
        <FactorySelector
          factory={factory}
          selectedFactory={selectedFactory}
          onSelect={onSelect}
        />
      )}
      {!editable && <FactoryIcon />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginLeft: '5px',
        }}
      >
        <p>
          {factory.factory_id} - {factory.factory_name}
          {!factory.factory_active &&
            factory.factory_active !== undefined &&
            dateCheck(factory.effective_end_date) && (
              <Chip
                style={{
                  marginLeft: '10px',
                  color: 'white',
                }}
                size="dense"
                color="alert"
              >
                Inactive Factory
              </Chip>
            )}
        </p>
        <p>COP: {getCop(factory.factory_id, locations)}</p>
        <p>
          VOP:
          {factory.vendor_order_spec_id
            ? ' ' + factory.vendor_order_spec_id
            : 'N/A'}
        </p>
      </div>
    </div>
  )
}

interface AddedOrRemovedFactoryProps {
  factory: LeanFactory
  selectedFactory?: LeanFactory
  onSelect?: (factory: LeanFactory) => void
  locations?: Location[]
  editable: boolean
  changeReason?: string
}

const AddedOrRemovedFactory: React.FC<AddedOrRemovedFactoryProps> = ({
  factory,
  selectedFactory,
  onSelect,
  locations,
  editable,
}) => {
  const borderStyle = factory.is_added
    ? '2px solid #00A300'
    : '2px solid #b85300'
  return (
    <div
      style={{
        display: 'flex',
        minWidth: '500px',
        marginBottom: '15px',
        border: borderStyle,
        padding: '10px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex' }}>
        <FactoryIcon />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: '5px',
          }}
        >
          <p>{factory.factory_name}</p>
          <p>COP: {getCop(factory.factory_id, locations)}</p>
          <p>VOP: {factory.vendor_order_spec_id}</p>
        </div>
      </div>
      <div>
        <FontAwesomeIcon
          icon={factory.is_added ? faCalendarPlus : faCalendarMinus}
          color="black"
          size="2x"
          style={{ paddingTop: '5px' }}
        />
        {factory.is_added ? 'added' : 'removed'}
      </div>
    </div>
  )
}

interface VOPChangeTimelineProps {
  factory: LeanFactory
  selectedFactory?: LeanFactory
  onSelect?: (factory: LeanFactory) => void
  locations?: Location[]
  editable: boolean
  changeReason?: string
}

const VOPChangeTimeline: React.FC<VOPChangeTimelineProps> = ({
  factory,
  selectedFactory,
  onSelect,
  locations,
  editable,
}) => {
  const borderStyle = '2px solid #b85300'
  return (
    <div
      style={{
        display: 'flex',
        minWidth: '500px',
        marginBottom: '15px',
        border: borderStyle,
        padding: '10px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex' }}>
        <FactoryIcon />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: '5px',
          }}
        >
          <p>{factory.factory_name}</p>
          <p>COP: {getCop(factory.factory_id, locations)}</p>
          <p>VOP: {factory.vendor_order_spec_id}</p>
        </div>
      </div>
      <div>
        <FontAwesomeIcon
          icon={faCalendarPlus}
          color="black"
          size="2x"
          style={{ paddingTop: '5px' }}
        />
        {'VOP Changed'}
      </div>
    </div>
  )
}

interface FactorySelectorProps {
  factory: LeanFactory
  selectedFactory?: LeanFactory
  onSelect?: (factory: LeanFactory) => void
}
const FactorySelector: React.FC<FactorySelectorProps> = ({
  factory,
  selectedFactory,
  onSelect,
}) => {
  const id = factory.factory_id + factory.effective_start_date
  return (
    <Form.Field
      id={id}
      type="radio"
      options={[
        {
          value: 'id',
        },
      ]}
      onUpdate={(id: any, value: any) => {
        onSelect?.(factory)
      }}
      key={id}
    />
  )
}

const FactoryIcon = () => {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#666',
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        justifyContent: 'center',
        marginLeft: '5px',
      }}
    >
      <FontAwesomeIcon
        icon={faIndustry}
        color="white"
        size="lg"
        style={{ paddingTop: '5px' }}
      />
    </div>
  )
}

export default FactoryList
