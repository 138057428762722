import {
  Button,
  Card,
  Grid,
  Pagination,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import React, { useEffect, useState } from 'react'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { ItemDetailsType, LeanFactory } from '../../types/ItemDetailsType'
import { ItemVendorFactoryTimeline } from '../../types/ItemVendorFactoryType'
import { getDefaultEndDate, getTodaysDate } from '../../utils/Constants'
import {
  addFactoryToFactoryTimeline,
  createEditableFactoryTimeline,
  createNewFactoryTimeline,
  hasGapsInTimeline,
  hasTimelineChanged,
  removeFactoryFromFactoryTimeline,
  validateFactoryRemoval,
} from '../../utils/FactoriesTimelineUtil'
import LabelValue from '../Common/LabelValue'
import { ViewFactoriesTimeline } from '../FactoryTimeline/View/ViewFactoriesTimeline'

interface ReviewTimelinesProps {
  itemsByVendor: { [key: string]: ItemDetailsType[] }
  factoryEffectiveStartDate?: Date
  factoryEffectiveEndDate?: Date
  newFactoryLocationOption: { [key: string]: AutoCompleteOptionType }
  newVOPOption: { [key: string]: AutoCompleteOptionType }
  removedFactoryLocationOption: { [key: string]: AutoCompleteOptionType }
  onNext: (
    itemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
    unchangedItemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
    invalidItemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
  ) => void
  onPrev: () => void
}

const ReviewTimelines: React.FC<ReviewTimelinesProps> = ({
  factoryEffectiveStartDate,
  factoryEffectiveEndDate,
  newFactoryLocationOption,
  removedFactoryLocationOption,
  newVOPOption,
  itemsByVendor,
  onNext,
  onPrev,
}) => {
  const { getVendorByGmsVendorNumber } = useBusinessPartnerInfo()
  const [itemVendorFactoryTimelines, setItemVendorFactoryTimelines] = useState<
    ItemVendorFactoryTimeline[]
  >([])
  const [
    invalidItemVendorFactoryTimelines,
    setInvalidItemVendorFactoryTimelines,
  ] = useState<ItemVendorFactoryTimeline[]>([])
  const [
    unchangedItemVendorFactoryTimelines,
    setUnchangedItemVendorFactoryTimelines,
  ] = useState<ItemVendorFactoryTimeline[]>([])

  useEffect(() => {
    if (factoryEffectiveStartDate && factoryEffectiveEndDate) {
      const vendorIds = Object.keys(itemsByVendor)
      let vop = ''
      let itemVendorFactoryTimelinesTemp: ItemVendorFactoryTimeline[] = []
      let invalidFactoryTimelinesTemp: ItemVendorFactoryTimeline[] = []
      let unchangedFactoryTimelinesTemp: ItemVendorFactoryTimeline[] = []
      vendorIds.forEach((vendorId) => {
        itemsByVendor[vendorId].forEach((item) => {
          let timelineExists = true
          let editableFactoryTimeline = createNewFactoryTimeline()
          let isDateAdjusted = false
          let isValidFactoryRemoval = !removedFactoryLocationOption[vendorId] //default to false when there are factory to be remove
          const vendorFactoryTimeline = item.vendor_factory_timelines.filter(
            (fact) => fact.vendor_id === vendorId.split('-')[0],
          )[0]
          if (
            vendorFactoryTimeline &&
            vendorFactoryTimeline.factory_timelines
          ) {
            editableFactoryTimeline = createEditableFactoryTimeline({
              factoryTimelineItems: vendorFactoryTimeline.factory_timelines,
            })
          } else {
            timelineExists = false
            isDateAdjusted = true
          }

          if (newVOPOption[vendorId]) {
            vop = newVOPOption[vendorId].id
          } else {
            vop = 'invalidVOP'
          }
          if (removedFactoryLocationOption[vendorId] && timelineExists) {
            isValidFactoryRemoval = validateFactoryRemoval(
              editableFactoryTimeline,
              removedFactoryLocationOption[vendorId].id,
              factoryEffectiveStartDate,
              factoryEffectiveEndDate,
            ) //check whether removal is valid
            if (isValidFactoryRemoval) {
              //Remove factory only when valid
              const factoryToRemove: LeanFactory | undefined = {
                factory_id: removedFactoryLocationOption[vendorId].id,
                factory_name: removedFactoryLocationOption[vendorId].value,
                effective_start_date: factoryEffectiveStartDate,
                effective_end_date: factoryEffectiveEndDate,
                vendor_order_spec_id: vop,
                is_removed: true,
                factory_active: true,
              }
              editableFactoryTimeline = {
                factoryTimelineItems: removeFactoryFromFactoryTimeline(
                  factoryToRemove,
                  editableFactoryTimeline,
                ),
              }
            } else {
              //undo the start date change
              editableFactoryTimeline = {
                factoryTimelineItems: vendorFactoryTimeline.factory_timelines,
              }
            }
          }
          if (
            newFactoryLocationOption[vendorId] &&
            newVOPOption[vendorId] &&
            isValidFactoryRemoval
          ) {
            const factoryToAdd: LeanFactory | undefined = {
              factory_id: newFactoryLocationOption[vendorId].id,
              factory_name: newFactoryLocationOption[vendorId].value,
              vendor_order_spec_id: vop,
              effective_start_date: timelineExists
                ? factoryEffectiveStartDate
                : getTodaysDate(),
              effective_end_date: timelineExists
                ? factoryEffectiveEndDate
                : getDefaultEndDate(),
              is_added: true,
              factory_active: true,
            }

            editableFactoryTimeline = {
              factoryTimelineItems: addFactoryToFactoryTimeline(
                factoryToAdd,
                editableFactoryTimeline,
              ),
            }
          }

          if (
            newFactoryLocationOption[vendorId] ||
            removedFactoryLocationOption[vendorId]
          ) {
            if (
              removedFactoryLocationOption[vendorId] &&
              !isValidFactoryRemoval
            ) {
              unchangedFactoryTimelinesTemp.push({
                tcin: item.tcin,
                dpci: item.dpci,
                vendor: getVendorByGmsVendorNumber(vendorId),
                factoryTimelineItems:
                  editableFactoryTimeline.factoryTimelineItems,
                isDateAdjusted: isDateAdjusted,
                hasInvalidFactoryRemoval: true,
              })
            } else if (hasGapsInTimeline(editableFactoryTimeline)) {
              invalidFactoryTimelinesTemp.push({
                tcin: item.tcin,
                dpci: item.dpci,
                vendor: getVendorByGmsVendorNumber(vendorId),
                factoryTimelineItems:
                  editableFactoryTimeline.factoryTimelineItems,
                isDateAdjusted: isDateAdjusted,
              })
            } else if (!hasTimelineChanged(editableFactoryTimeline)) {
              unchangedFactoryTimelinesTemp.push({
                tcin: item.tcin,
                dpci: item.dpci,
                vendor: getVendorByGmsVendorNumber(vendorId),
                factoryTimelineItems:
                  editableFactoryTimeline.factoryTimelineItems,
                isDateAdjusted: isDateAdjusted,
              })
            } else {
              itemVendorFactoryTimelinesTemp.push({
                tcin: item.tcin,
                dpci: item.dpci,
                vendor: getVendorByGmsVendorNumber(vendorId),
                factoryTimelineItems:
                  editableFactoryTimeline.factoryTimelineItems,
                isDateAdjusted: isDateAdjusted,
              })
            }
          }
        })
      })
      setItemVendorFactoryTimelines(itemVendorFactoryTimelinesTemp)
      setInvalidItemVendorFactoryTimelines(invalidFactoryTimelinesTemp)
      setUnchangedItemVendorFactoryTimelines(unchangedFactoryTimelinesTemp)
    }
  }, [
    factoryEffectiveEndDate,
    factoryEffectiveStartDate,
    newFactoryLocationOption,
    removedFactoryLocationOption,
    newVOPOption,
    getVendorByGmsVendorNumber,
    itemsByVendor,
  ])

  return (
    <TimelinesTab
      updatedItemVendorFactoryTimelines={itemVendorFactoryTimelines}
      unchangedItemVendorFactoryTimelines={unchangedItemVendorFactoryTimelines}
      invalidItemVendorFactoryTimelines={invalidItemVendorFactoryTimelines}
      onNext={onNext}
      onPrev={onPrev}
    />
  )
}

const TimelinesTab: React.FC<{
  updatedItemVendorFactoryTimelines: ItemVendorFactoryTimeline[]
  unchangedItemVendorFactoryTimelines: ItemVendorFactoryTimeline[]
  invalidItemVendorFactoryTimelines: ItemVendorFactoryTimeline[]
  onNext: (
    itemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
    unchangedItemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
    invalidItemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
  ) => void
  onPrev: () => void
}> = ({
  updatedItemVendorFactoryTimelines,
  unchangedItemVendorFactoryTimelines,
  invalidItemVendorFactoryTimelines,
  onNext,
  onPrev,
}) => {
  const [activeTab, setActiveTab] = useState<{ name: string }>({
    name: 'updated',
  })
  const [itemVendorFactoryTimelines, setItemVendorFactoryTimelines] = useState<
    ItemVendorFactoryTimeline[]
  >(updatedItemVendorFactoryTimelines)
  const [index, setIndex] = useState<number>(0)
  const [currentItemVendorFactory, setCurrentItemVendorFactory] =
    useState<ItemVendorFactoryTimeline>()
  const { businessPartner } = useBusinessPartnerInfo()

  useEffect(() => {
    setCurrentItemVendorFactory(itemVendorFactoryTimelines[index])
  }, [index, itemVendorFactoryTimelines])

  useEffect(() => {
    setIndex(0)

    switch (activeTab.name) {
      case 'updated':
        setItemVendorFactoryTimelines(updatedItemVendorFactoryTimelines)
        break
      case 'unchanged':
        setItemVendorFactoryTimelines(unchangedItemVendorFactoryTimelines)
        break
      case 'errors':
        setItemVendorFactoryTimelines(invalidItemVendorFactoryTimelines)
        break
    }
  }, [
    activeTab,
    updatedItemVendorFactoryTimelines,
    unchangedItemVendorFactoryTimelines,
    invalidItemVendorFactoryTimelines,
  ])

  return (
    <Card style={{ width: '40vw' }}>
      <Tabs
        activeTab={activeTab.name}
        onTabSelect={(event: any, tab: any) => {
          setActiveTab(tab)
        }}
      >
        <Tabs.Item name="updated">
          Updated ({updatedItemVendorFactoryTimelines.length})
        </Tabs.Item>
        {unchangedItemVendorFactoryTimelines.length > 0 && (
          <Tabs.Item name="unchanged">
            Unchanged ({unchangedItemVendorFactoryTimelines.length})
          </Tabs.Item>
        )}
        {invalidItemVendorFactoryTimelines.length > 0 && (
          <Tabs.Item name="errors">
            Errors ({invalidItemVendorFactoryTimelines.length})
          </Tabs.Item>
        )}
      </Tabs>
      {updatedItemVendorFactoryTimelines.length === 0 &&
        activeTab.name === 'updated' && (
          <>
            <br />
            <p className="hc-bg-highlight hc-pa-min">
              Request is incomplete. Please add a factory and VOP
              {/* There are no changes to items. Please see Unchanged/Errors Tab */}
            </p>
            <br />
          </>
        )}
      {invalidItemVendorFactoryTimelines.length > 0 &&
        activeTab.name === 'updated' && (
          <>
            <br />
            <p className="hc-bg-highlight hc-pa-min">
              There are errors. Please see Errors Tab. Please resolve them.
            </p>
            <br />
          </>
        )}
      {itemVendorFactoryTimelines.length > 0 &&
        activeTab.name === 'unchanged' && (
          <>
            <br />
            <p className="hc-bg-highlight hc-pa-min">
              {itemVendorFactoryTimelines.filter(
                (factoryTimeline: ItemVendorFactoryTimeline) =>
                  factoryTimeline.hasInvalidFactoryRemoval,
              ).length > 0
                ? 'Below Factories are unchanged because the factory to replace (or delete) did not exist in the timeline.'
                : 'Below items are unchanged.'}
            </p>
            <br />
          </>
        )}
      {itemVendorFactoryTimelines.length > 0 && activeTab.name === 'errors' && (
        <>
          <br />
          <p className="hc-bg-highlight hc-pa-min">
            There is no other active factory for these item(s) if this factory
            is deleted. Please choose Replace action in Step 1 or remove these
            items from selection.
          </p>
          <br />
        </>
      )}
      {itemVendorFactoryTimelines.length > 0 && (
        <Card>
          <div className="hc-pa-normal">
            <Grid.Container justify="space-between">
              <Grid.Item>
                <LabelValue
                  label="TCIN : "
                  value={currentItemVendorFactory?.tcin}
                  dense
                />
                <LabelValue
                  label="Vendor : "
                  value={
                    currentItemVendorFactory?.vendor?.gms_vendor_number +
                    '-' +
                    currentItemVendorFactory?.vendor?.vendor_name
                  }
                  dense
                />
              </Grid.Item>
              <Grid.Item>
                <div
                  className="hc-bg-grey07 hc-pa-min"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <p>PREVIEW</p>
                  <Pagination
                    iconsOnly
                    currentPage={index + 1}
                    totalPages={itemVendorFactoryTimelines.length}
                    onRequestNext={() => {
                      index !== itemVendorFactoryTimelines.length - 1 &&
                        setIndex(index + 1)
                    }}
                    onRequestPrev={() => {
                      index !== 0 && setIndex(index - 1)
                    }}
                  />
                </div>
              </Grid.Item>
            </Grid.Container>
            <ViewFactoriesTimeline
              factoryTimeline={{
                factoryTimelineItems:
                  currentItemVendorFactory?.factoryTimelineItems ?? [],
              }}
              locations={businessPartner.locations}
              changeReason={'Review'}
              changeRequestedBy={'Under Review'}
              isDateAdjusted={currentItemVendorFactory?.isDateAdjusted}
              key="changeRequestView"
            />
          </div>
        </Card>
      )}
      <br></br>
      {activeTab.name === 'updated' && (
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Button type="secondary" onClick={() => onPrev()} size="dense">
              <span style={{ textTransform: 'capitalize' }}>
                Back: Choose Factory
              </span>
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              disabled={itemVendorFactoryTimelines.length === 0}
              type="primary"
              onClick={() =>
                onNext(
                  itemVendorFactoryTimelines,
                  unchangedItemVendorFactoryTimelines,
                  invalidItemVendorFactoryTimelines,
                )
              }
              size="dense"
            >
              <span style={{ textTransform: 'capitalize' }}>Next: Submit</span>
            </Button>
          </Grid.Item>
        </Grid.Container>
      )}
    </Card>
  )
}

export default ReviewTimelines
