import React, { useState, useEffect, useCallback, useMemo } from 'react'

import {
  FactoryTimeline,
  ItemDetailsType,
  LeanFactory,
} from '../../../types/ItemDetailsType'
import {
  Button,
  Input,
  Timeline,
  ToastProvider,
  ToasterToastProps,
} from '@enterprise-ui/canvas-ui-react'
import {
  addFactoryToFactoryTimeline,
  createEditableFactoryTimeline,
  isFactoryTimelineUpdated,
  removeFactoryFromFactoryTimeline,
  createNewFactoryTimeline,
  previewNewTimeline,
  buildChangeRequestTimeline,
  chgVOPToFactoryTimeline,
  createEditableVOPFactoryTimeline,
} from '../../../utils/FactoriesTimelineUtil'
import {
  BusinessPartnerType,
  Location,
} from '../../../types/BusinessPartnerType'
import FactoryList from '../Helpers/FactoryList'
import { captureButtonClickEvent } from '../../../services/analytics/events-service'
import {
  InternalUserActions,
  VendorActions,
} from '../../AllChangeRequests/Actions/Actions'
import {
  getDefaultEndDate,
  ReviewStatus,
  UserType,
} from '../../../utils/Constants'
import { useUserInfo } from '../../../context/UserInfoContext'
import { ChangeRequestType } from '../../../types/ChangeRequestType'
import SubmitChangeRequestModal from '../../ChangeRequest/SubmitChangeRequestModal'
import ChangeHistoryModal from '../../ChangeHistory/ChangeHistoryModal'
import { BulkChangeRequestType } from '../../../types/BulkChangeRequestType'
import { createChangeRequest } from '../../ChangeRequest/ChangeRequestUtil'
import { submitChangeRequest } from '../../../services/ChangeRequestService'
import { AutoCompleteOptionType } from '../../../types/AutoCompleteOptionType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { EditFactoryActions } from '../../EditFactory/EditFactoryActions'
import { ViewFactoriesTimeline } from '../View/ViewFactoriesTimeline'
import LabelValue from '../../Common/LabelValue'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { ChangeVOP } from '../../EditVOP/ChangeVOP'
import { dateCheck } from '../../../utils/DateCheck'
import { useNavigate } from 'react-router'

interface EditFactoriesTimelineProps {
  factoryTimeline: FactoryTimeline
  locations?: Location[]
  selectedVendor: AutoCompleteOptionType
  selectedBusinessPartner?: BusinessPartnerType
  changeRequest?: ChangeRequestType[]
  itemDetails: ItemDetailsType
  onFactoryAction: () => void
}

const changeRequestToast: ToasterToastProps = {
  heading: 'Change Request Created',
  message: 'Email notification sent to Target',
  type: 'success',
}

const getInitiatedChangeRequests = (changeRequests: ChangeRequestType[]) => {
  return changeRequests.filter(
    (request) => request.review_status === ReviewStatus.INITIATED,
  )
}

const getPastChangeRequests = (changeRequests: ChangeRequestType[]) => {
  return changeRequests
    .filter(
      (request) =>
        request.review_status === ReviewStatus.APPROVED ||
        request.review_status === ReviewStatus.REJECTED ||
        request.review_status === ReviewStatus.CANCELLED,
    )
    .sort(
      (
        changeRequest1: ChangeRequestType,
        changeRequest2: ChangeRequestType,
      ) => {
        return changeRequest1.created_on < changeRequest2.created_on ? 1 : -1
      },
    )
}

export const EditFactoriesTimeline: React.FC<EditFactoriesTimelineProps> = ({
  factoryTimeline,
  locations = [],
  selectedVendor,
  selectedBusinessPartner,
  changeRequest,
  itemDetails,
  onFactoryAction,
}) => {
  const editableFactoryTimeLine = useMemo(() => {
    return createEditableFactoryTimeline(factoryTimeline)
  }, [factoryTimeline])

  const [toggleView, setToggleView] = useState<boolean>(false)
  const currentView = toggleView ? 'Edit' : 'Current'
  const [toggleVOPView, setToggleVOPView] = useState<boolean>(false)
  const currentVOPView = toggleVOPView ? 'EditVOP' : 'CurrentVOP'
  var factoryCnt: number = 0
  const navigate = useNavigate()

  const [modifiedFactoryTimeLine, setModifiedFactoryTimeLine] =
    useState<FactoryTimeline>(() => {
      return createEditableFactoryTimeline(factoryTimeline)
    })

  const [modifiedVOPTimeLine, setModifiedVOPTimeLine] =
    useState<FactoryTimeline>(() => {
      return createEditableVOPFactoryTimeline(factoryTimeline)
    })

  const hasEmptyTimeline =
    modifiedFactoryTimeLine.factoryTimelineItems.length === 0

  const [selectedFactory, setSelectedFactory] = useState<LeanFactory>()

  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false)

  const [vopChanged, setIsVOPChanged] = useState<boolean>(false)

  const [isSubmitChangesModalOpen, setIsSubmitChangesModalOpen] =
    useState<boolean>(false)

  const [isChangeHistoryModalOpen, setIsChangeHistoryModalOpen] =
    useState<boolean>(false)

  const defFactoryTimeLineCount = () => {
    modifiedFactoryTimeLine.factoryTimelineItems.forEach(
      (factoryTimelineItem) => {
        factoryTimelineItem.factories.forEach((leanFactory) => {
          if (
            leanFactory.effective_end_date.getFullYear() ===
              getDefaultEndDate().getFullYear() &&
            !leanFactory.is_removed
          ) {
            factoryCnt++
          }
        })
      },
    )
    return factoryCnt
  }

  const initiatedChangeRequests = getInitiatedChangeRequests(
    changeRequest ?? [],
  )
  const hasChangeRequest = initiatedChangeRequests.length > 0

  const changeRequestTimelineItems =
    initiatedChangeRequests[0]?.diff_factory_timelines

  const { userInfo } = useUserInfo()

  const pastChangeRequests = getPastChangeRequests(changeRequest ?? [])

  const makeToast = ToastProvider.useToaster()

  const addFactory = useCallback(
    (factoryToAdd: LeanFactory) => {
      const factoryTimelineToEdit =
        modifiedFactoryTimeLine.factoryTimelineItems.length === 0
          ? createNewFactoryTimeline()
          : modifiedFactoryTimeLine
      factoryToAdd.is_added = true
      const newFactoryTimelineItems = addFactoryToFactoryTimeline(
        factoryToAdd,
        factoryTimelineToEdit,
      )

      setModifiedFactoryTimeLine({
        factoryTimelineItems: newFactoryTimelineItems,
      })
    },
    [modifiedFactoryTimeLine],
  )

  const removeFactory = useCallback(
    (factoryToRemove: LeanFactory) => {
      setSelectedFactory(undefined)
      if (factoryToRemove.is_added) {
        const newFactoryTimelineItems = removeFactoryFromFactoryTimeline(
          factoryToRemove,
          modifiedFactoryTimeLine,
        )
        setModifiedFactoryTimeLine({
          factoryTimelineItems: newFactoryTimelineItems,
        })
        return {
          factoryTimelineItems: newFactoryTimelineItems,
        }
      } else {
        factoryToRemove.is_removed = true
        setModifiedFactoryTimeLine({
          factoryTimelineItems: modifiedFactoryTimeLine.factoryTimelineItems,
        })
        return {
          factoryTimelineItems: modifiedFactoryTimeLine.factoryTimelineItems,
        }
      }
    },
    [modifiedFactoryTimeLine],
  )

  const changeVOP = useCallback(
    (chgFactory: LeanFactory) => {
      setIsVOPChanged(true)
      setSelectedFactory(undefined)
      chgFactory.is_vop_changed = true
      const chgFactoryTimeline = chgVOPToFactoryTimeline(chgFactory)
      setModifiedVOPTimeLine(chgFactoryTimeline)
      return {
        factoryTimelineItem: modifiedVOPTimeLine,
      }
    },
    [modifiedVOPTimeLine],
  )

  const replaceFactory = useCallback(
    (factoryToRemove: LeanFactory, factoryToAdd: LeanFactory) => {
      setSelectedFactory(undefined)
      const factoryTimeLineAfterRemove = removeFactory(factoryToRemove)
      factoryToAdd.is_added = true
      const newFactoryTimelineItems = addFactoryToFactoryTimeline(
        factoryToAdd,
        factoryTimeLineAfterRemove,
      )
      setModifiedFactoryTimeLine({
        factoryTimelineItems: newFactoryTimelineItems,
      })
    },
    [removeFactory],
  )

  const checkFactories = () => {
    for (var factoryTimeLineItem of modifiedFactoryTimeLine.factoryTimelineItems) {
      if (dateCheck(factoryTimeLineItem.effective_end_date)) {
        for (var leanFactory of factoryTimeLineItem.factories) {
          if (!leanFactory.factory_active) {
            return false
          }
        }
      }
    }
    return true
  }

  useEffect(() => {
    const isFactoryTimelineChanged = isFactoryTimelineUpdated(
      modifiedFactoryTimeLine,
    )
    if (vopChanged) {
      setIsSaveEnabled(true)
    } else {
      setIsSaveEnabled(isFactoryTimelineChanged)
    }
  }, [
    editableFactoryTimeLine,
    modifiedFactoryTimeLine,
    vopChanged,
    modifiedVOPTimeLine,
  ])

  const onSubmitChangeRequest = (
    reasonForChange: string,
    changeDescription: string,
    cycleDescription?: string[],
    internalContacts?: string[],
    externalContacts?: string[],
    vopChange?: Boolean,
  ): Promise<any> => {
    if (selectedBusinessPartner) {
      const newTimeline: FactoryTimeline = previewNewTimeline(
        modifiedFactoryTimeLine,
      )
      var changedFactoryTimeline = buildChangeRequestTimeline(
        modifiedFactoryTimeLine,
        factoryTimeline,
      )

      const changeRequest: BulkChangeRequestType = createChangeRequest({
        selectedBusinessPartner,
        tcin: itemDetails.tcin,
        dpci: itemDetails.dpci,
        selectedVendor,
        newTimeline,
        changedFactoryTimeline: changedFactoryTimeline,
        modifiedVOPTimeLine,
        reasonForChange,
        changeDescription,
        cycleDescription,
        userInfo,
        internalContacts,
        externalContacts,
        vopChange,
      })

      return submitChangeRequest([changeRequest]).then((resp: any) => {
        setIsSubmitChangesModalOpen(false)

        userInfo.user_type === UserType.EXTERNAL &&
          !vopChange &&
          makeToast(changeRequestToast)
        onFactoryAction()
        navigate(0)
        return resp
      })
    }

    return Promise.resolve()
  }

  return (
    <div className="hc-pa-normal">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          textTransform: 'capitalize',
          marginBottom: 5,
        }}
      >
        {!hasChangeRequest && (
          <div style={{ marginLeft: '10px' }}>
            <Input.Toggle
              id={`ID: ${selectedVendor.id}`}
              label="Edit Factory"
              disabled={toggleVOPView}
              onChange={(event: any) => {
                captureButtonClickEvent('Factory Edit Toggle')
                setToggleView(!toggleView)
              }}
            />
          </div>
        )}
        {!hasChangeRequest && (
          <div style={{ marginLeft: '10px' }}>
            <Input.Toggle
              id={selectedVendor.label}
              disabled={toggleView}
              label="Edit VOP"
              onChange={(event: any) => {
                captureButtonClickEvent('VOP Edit Toggle')
                setToggleVOPView(!toggleVOPView)
              }}
            />
          </div>
        )}
      </div>

      {!checkFactories() && (
        <div
          style={{
            display: 'flex',
            padding: '10px',
            backgroundColor: '#e2ba0c',
            color: 'white',
            borderRadius: '10px',
          }}
        >
          <FontAwesomeIcon
            className="hc-mr-dense"
            icon={faExclamationTriangle}
            size="lg"
          ></FontAwesomeIcon>
          <p>
            You have invalid factories in the timeline, this may be because the
            factory no longer exists in VMM
            {/* <a
              style={{
                color: 'blue',
              }}
              href=""
            >
              learn more
            </a> */}
          </p>
        </div>
      )}
      <div>
        {!hasChangeRequest && currentView === 'Edit' && (
          <EditFactoryActions
            selectedFactory={selectedFactory}
            onAddFactory={addFactory}
            onReplaceFactory={replaceFactory}
            onDeleteFactory={removeFactory}
            locations={locations}
            hideDates={hasEmptyTimeline}
            factoryWithDefEndDt={defFactoryTimeLineCount()}
            selectedVendor={selectedVendor}
            itemDetails={itemDetails}
          />
        )}
        {!hasChangeRequest && currentVOPView === 'EditVOP' && (
          <ChangeVOP
            selectedFactory={selectedFactory}
            onChangeVOP={changeVOP}
            selectedVendor={selectedVendor}
            itemDetails={itemDetails}
          />
        )}
        {hasChangeRequest &&
          (userInfo.user_type === UserType.INTERNAL ? (
            <InternalUserActions
              selectedChangeRequests={initiatedChangeRequests}
              onActionCompleted={onFactoryAction}
            />
          ) : (
            <VendorActions
              selectedChangeRequests={initiatedChangeRequests}
              onActionCompleted={onFactoryAction}
            />
          ))}
      </div>
      <div
        style={{
          border: '1px solid grey',
          borderRadius: '10px',
          marginBottom: 5,
        }}
      >
        {!hasEmptyTimeline && !hasChangeRequest && currentView === 'Edit' && (
          <Timeline>
            {modifiedFactoryTimeLine.factoryTimelineItems.map(
              (factoryTimelineItem) => (
                <Timeline.Item
                  key={factoryTimelineItem.effective_start_date.getTime()}
                >
                  <FactoryList
                    factories={factoryTimelineItem.factories}
                    selectedFactory={selectedFactory}
                    onSelect={(factory: LeanFactory) => {
                      setSelectedFactory(factory)
                    }}
                    effectiveStartDate={
                      factoryTimelineItem.effective_start_date
                    }
                    effectiveEndDate={factoryTimelineItem.effective_end_date}
                    locations={locations}
                    editable={true}
                    vopChanged={false}
                  />
                </Timeline.Item>
              ),
            )}
          </Timeline>
        )}
        {!hasEmptyTimeline &&
          !hasChangeRequest &&
          currentVOPView === 'EditVOP' && (
            <Timeline>
              {modifiedVOPTimeLine.factoryTimelineItems.map(
                (factoryTimelineItem) => (
                  <Timeline.Item
                    key={factoryTimelineItem.effective_start_date.getTime()}
                  >
                    <FactoryList
                      factories={factoryTimelineItem.factories}
                      selectedFactory={selectedFactory}
                      onSelect={(factory: LeanFactory) => {
                        setSelectedFactory(factory)
                      }}
                      effectiveStartDate={
                        factoryTimelineItem.effective_start_date
                      }
                      effectiveEndDate={factoryTimelineItem.effective_end_date}
                      locations={locations}
                      editable={true}
                      vopChanged={false}
                    />
                  </Timeline.Item>
                ),
              )}
            </Timeline>
          )}
        {!hasEmptyTimeline &&
          !hasChangeRequest &&
          currentView !== 'Edit' &&
          currentVOPView !== 'EditVOP' && (
            <ViewFactoriesTimeline
              factoryTimeline={factoryTimeline}
              locations={selectedBusinessPartner?.locations}
              key="readView"
            />
          )}
        {hasEmptyTimeline && !hasChangeRequest && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px',
            }}
            className="hc-clr-error"
          >
            <FontAwesomeIcon
              className="hc-mr-dense"
              icon={faExclamationTriangle}
              size="lg"
            ></FontAwesomeIcon>
            <p className="hc-clr-error">
              There are no factories in the timeline. Please add a Factory
            </p>
          </div>
        )}
        {hasChangeRequest && (
          <Timeline>
            {changeRequestTimelineItems?.map((factoryTimelineItem) => (
              <Timeline.Item
                key={factoryTimelineItem.effective_start_date.getTime()}
              >
                <FactoryList
                  factories={factoryTimelineItem.factories}
                  selectedFactory={selectedFactory}
                  onSelect={(factory: LeanFactory) =>
                    setSelectedFactory(factory)
                  }
                  effectiveStartDate={factoryTimelineItem.effective_start_date}
                  effectiveEndDate={factoryTimelineItem.effective_end_date}
                  locations={locations}
                  editable={false}
                  vopChanged={vopChanged}
                />
              </Timeline.Item>
            ))}
          </Timeline>
        )}
      </div>
      <div
        className="hc-pa-normal"
        style={{
          display: 'flex',
          justifyContent: 'flex-begin',
          padding: 5,
        }}
      >
        <Button
          onClick={() => {
            captureButtonClickEvent('Change History')
            setIsChangeHistoryModalOpen(true)
          }}
          className="change-history"
          disabled={pastChangeRequests.length === 0}
        >
          <FontAwesomeIcon
            className="hc-mr-dense"
            icon={faHistory}
            size="lg"
          ></FontAwesomeIcon>
          VIEW HISTORY
        </Button>

        {currentView === 'Edit' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 4,
              paddingLeft: 450,
            }}
          >
            {!hasChangeRequest && (
              <Button
                type="secondary"
                onClick={() => {
                  captureButtonClickEvent('Factory Edit Undo')
                  setModifiedFactoryTimeLine(
                    createEditableFactoryTimeline(factoryTimeline),
                  )
                  setSelectedFactory(undefined)
                }}
              >
                UNDO
              </Button>
            )}
            {!hasChangeRequest && (
              <Button
                type="primary"
                disabled={!isSaveEnabled}
                onClick={() => {
                  captureButtonClickEvent('Factory Edit Save')
                  setIsSubmitChangesModalOpen(true)
                }}
                className="hc-ml-dense"
              >
                Save
              </Button>
            )}
          </div>
        )}
        {currentVOPView === 'EditVOP' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 5,
              paddingLeft: 450,
            }}
          >
            {!hasChangeRequest && (
              <Button
                type="secondary"
                onClick={() => {
                  captureButtonClickEvent('Factory Edit Undo')
                  setModifiedVOPTimeLine(
                    createEditableVOPFactoryTimeline(factoryTimeline),
                  )
                  setSelectedFactory(undefined)
                }}
              >
                UNDO
              </Button>
            )}
            {!hasChangeRequest && (
              <Button
                type="primary"
                disabled={!isSaveEnabled}
                onClick={() => {
                  captureButtonClickEvent('Factory Edit Save')
                  if (vopChanged) {
                    onSubmitChangeRequest('VOP Change', '', [], [], [], true)
                  } else setIsSubmitChangesModalOpen(true)
                }}
                className="hc-ml-dense"
              >
                Save
              </Button>
            )}
          </div>
        )}
      </div>
      {hasChangeRequest && (
        <div>
          <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            Change Request:
          </p>
          <LabelValue
            label="Requested by: "
            value={initiatedChangeRequests[0].external_contacts?.join()}
            dense
          />
          <div style={{ display: 'flex', fontSize: '12px' }}>
            <p>
              <strong>Requested on: </strong>
            </p>
            <p style={{ marginLeft: '4px' }}>
              <DateFormatter date={initiatedChangeRequests[0].created_on} />
            </p>
          </div>
          <LabelValue
            label="Reason: "
            value={` ${initiatedChangeRequests[0].reason_for_change} - ${initiatedChangeRequests[0].reason_for_change_description}`}
            dense
          />
          {initiatedChangeRequests[0].cycle_description && (
            <LabelValue
              label="Cycle: "
              value={initiatedChangeRequests[0].cycle_description?.join()}
              dense
            />
          )}
        </div>
      )}
      <SubmitChangeRequestModal
        isOpen={isSubmitChangesModalOpen}
        currentUserEmail={userInfo.email_id}
        onClose={() => setIsSubmitChangesModalOpen(false)}
        onSubmit={onSubmitChangeRequest}
      />
      <ChangeHistoryModal
        isOpen={isChangeHistoryModalOpen}
        onClose={() => {
          setIsChangeHistoryModalOpen(false)
        }}
        changeRequests={pastChangeRequests}
      />
    </div>
  )
}
